/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  configure as reactHotKeysConfigure,
  GlobalHotKeys,
} from 'react-hotkeys'
import { PlusCircleIcon } from '@heroicons/react/24/solid'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import debounce from 'lodash/debounce'

import { Transaction, TRANSACTION_NEW_ID } from '../types'
import { getTransactions, TransactionsResponse } from './queries'
import TransactionEntry from '../TransactionEntry/TransactionEntry'
import { generateRandomUUID, todayAsString } from '../../util'
import TransactionPresenter from '../TransactionEntry/TransactionPresenter'
import { TransactionEntryEditOutcome } from '../TransactionEntry/TransactionEntryEdit'
import PeriodSelect, {
  defaultSelectPeriod,
  OnSelectPeriodArgs,
} from '../PeriodSelect/PeriodSelect'
import MoneyAmount from '../MoneyAmount'
import {
  confirmReconciliation,
  isReconciliationReady,
  ReconcileState,
  reconciliationActualBalance,
  reconciliationDifference,
} from './reconciliation'

const TRANSACTION_NEW_INDEX = -1

// This is used to mimic VIM long-press behaviour
reactHotKeysConfigure({ ignoreRepeatedEventsWhenKeyHeldDown: false })

export default function TransactionList() {
  const { accountId } = useParams<{ accountId: string }>() as {
    accountId: string
  }

  const [searchParams, setSearchParams] = useSearchParams({})
  const focusId = searchParams.get('focusId')

  const navigate = useNavigate()

  const [startDate, setStartDate] = useState(defaultSelectPeriod().startDate)
  const [endDate, setEndDate] = useState(defaultSelectPeriod().endDate)
  const [searchTerm, setSearchTerm] = useState('')

  const [reconcileState, setReconcileState] = useState<ReconcileState>({
    active: false,
    confirming: false,
    starting: 0,
    initial: 0,
    expected: 0,
    statuses: {},
  })

  const initialData: TransactionsResponse = {
    transactions: [],
    startingBalance: 0,
    endingBalance: 0,
    count: 0,
  }

  const {
    isLoading,
    isError,
    data: { transactions, startingBalance } = initialData,
  } = useQuery({
    queryKey: ['transactions', accountId, startDate, endDate, searchTerm],
    queryFn: () =>
      getTransactions({
        accountId: accountId || 'all',
        startDate,
        endDate,
        searchTerm,
      }),
  })

  const queryClient = useQueryClient()

  const reconcileMutation = useMutation({
    mutationFn: confirmReconciliation,
    onSuccess: () => {
      setReconcileState({ ...reconcileState, active: false })
      queryClient.invalidateQueries({ queryKey: ['transactions'] })
    },
  })

  const [editingTransaction, setEditingTransaction] =
    useState<Transaction | null>(null)
  const [isInsertingTransaction, setIsInsertingTransaction] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)

  const transactionSum = useMemo(
    () => transactions.reduce((sum, t) => sum + t.amount, 0),
    [transactions],
  )

  const reconciledSum = (): number =>
    transactions.reduce(
      (sum, t) => sum + (reconcileState.statuses[t.id] ? t.amount : 0),
      0,
    )

  const isModifyingTransaction = () =>
    !!editingTransaction || isInsertingTransaction

  const keyMap = {
    NEXT_TRANSACTION: 'j',
    NEXT_TRANSACTION_PAGE: 'shift+j',
    PREVIOUS_TRANSACTION: 'k',
    PREVIOUS_TRANSACTION_PAGE: 'shift+k',
    EDIT_TRANSACTION: ['e', 'enter'],
    ADD_TRANSACTION: 'a',
    FIRST_TRANSACTION: 'g',
    LAST_TRANSACTION: 'shift+g',
  }

  const handleAddTransaction = () => {
    setIsInsertingTransaction(true)
    setSelectedIndex(TRANSACTION_NEW_INDEX)
    setEditingTransaction({
      id: TRANSACTION_NEW_ID,
      accountId,
      date: todayAsString(),
      payee: '',
      description: '',
      amount: 0,
      splits: [
        {
          description: '',
          amount: 0,
          matchAccountId: '',
          matchId: null,
          tag: '',
        },
      ],
      reconciled: false,
    })
  }

  const handleEditAccount = () => {
    navigate(`/accounts/${accountId}/edit`)
  }

  const handleStartReconcileMode = () => {
    // eslint-disable-next-line no-alert
    const expectedBalance = window.prompt('Enter balance')
    if (expectedBalance) {
      setReconcileState({
        active: true,
        confirming: false,
        starting: startingBalance,
        initial: startingBalance - reconciledSum(),
        expected: parseFloat(expectedBalance) * 100,
        statuses: Object.fromEntries(
          transactions.map((t) => [t.id, t.reconciled]),
        ),
      })
    }
  }

  const keyDependencies = [
    selectedIndex,
    isInsertingTransaction,
    editingTransaction,
    transactions?.length,
  ]

  const handleEditingTransaction = useCallback(
    (transaction: Transaction) => {
      setIsInsertingTransaction(false)
      setEditingTransaction(transaction)
      if (transaction && transactions) {
        setSelectedIndex(transactions.indexOf(transaction))
      }
    },
    [transactions],
  )

  const handleEditTransaction = (keyEvent: KeyboardEvent | undefined) => {
    if (
      !editingTransaction &&
      !isLoading &&
      !isError &&
      transactions &&
      selectedIndex !== null
    ) {
      keyEvent?.preventDefault()
      handleEditingTransaction(transactions[selectedIndex])
    }
  }

  const keymapHandlers = {
    NEXT_TRANSACTION: useCallback(() => {
      if (isModifyingTransaction()) {
        return
      }
      setSelectedIndex(
        selectedIndex === null
          ? 0
          : Math.min(selectedIndex + 1, (transactions?.length || 0) - 1),
      )
    }, keyDependencies),
    NEXT_TRANSACTION_PAGE: useCallback(() => {
      if (isModifyingTransaction()) {
        return
      }
      setSelectedIndex(
        selectedIndex === null
          ? 0
          : Math.min(selectedIndex + 10, (transactions?.length || 0) - 1),
      )
    }, keyDependencies),
    PREVIOUS_TRANSACTION: useCallback(() => {
      if (isModifyingTransaction()) {
        return
      }
      setSelectedIndex(
        selectedIndex === null ? null : Math.max(selectedIndex - 1, 0),
      )
    }, keyDependencies),
    PREVIOUS_TRANSACTION_PAGE: useCallback(() => {
      if (isModifyingTransaction()) {
        return
      }
      setSelectedIndex(
        selectedIndex === null ? null : Math.max(selectedIndex - 10, 0),
      )
    }, keyDependencies),
    EDIT_TRANSACTION: handleEditTransaction,
    ADD_TRANSACTION: handleAddTransaction,
    FIRST_TRANSACTION: useCallback(() => {
      setSelectedIndex(0)
    }, keyDependencies),
    LAST_TRANSACTION: useCallback(() => {
      setSelectedIndex((transactions?.length || 0) - 1)
    }, keyDependencies),
  }

  const handleStopEditTransaction = (
    _transaction: Transaction,
    outcome: TransactionEntryEditOutcome,
  ) => {
    setEditingTransaction(null)

    if (isInsertingTransaction) {
      setIsInsertingTransaction(false)
      if (outcome === 'saved') {
        handleAddTransaction()
      } else {
        setSelectedIndex(transactions ? transactions.length - 1 : null)
      }
    }
  }

  const handlePeriodSelect = ({
    startDate: newStartDate,
    endDate: newEndDate,
  }: OnSelectPeriodArgs) => {
    setStartDate(newStartDate)
    setEndDate(newEndDate)
  }

  const handleSearchChange = debounce(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setSearchTerm(event.target.value),
    300,
  )
  const handleConfirmReconciliation = async () => {
    setReconcileState({ ...reconcileState, confirming: true })
    reconcileMutation.mutate({
      state: reconcileState,
      transactions,
    })
  }

  let balance = startingBalance

  const renderTransaction = (transaction: Transaction, index: number) => {
    balance += transaction.amount

    const transactionWithReconciliationStatus = reconcileState.active
      ? { ...transaction, reconciled: reconcileState.statuses[transaction.id] }
      : transaction

    return (
      <TransactionEntry
        key={
          transaction.id === TRANSACTION_NEW_ID
            ? generateRandomUUID()
            : transaction.id
        }
        transaction={transactionWithReconciliationStatus}
        balance={balance}
        selected={index === selectedIndex}
        editing={editingTransaction?.id === transaction.id}
        onStartEdit={handleEditingTransaction}
        onStopEdit={handleStopEditTransaction}
        onSelect={() => {
          if (reconcileState.active) {
            setReconcileState((prev) => ({
              ...prev,
              statuses: {
                ...prev.statuses,
                [transaction.id]: !prev.statuses[transaction.id],
              },
            }))
          }
          setSelectedIndex(index)
        }}
      />
    )
  }

  const renderHeaders = () => (
    <TransactionPresenter
      date="Date"
      category="Category"
      tag="Tag"
      payee="Payee"
      description="Description"
      reconciled="R"
      amount="Amount"
      balanceOrActions="Balance"
    />
  )

  const renderNewTransaction = () => {
    if (!isInsertingTransaction || !editingTransaction) {
      return (
        <TransactionPresenter
          onClick={() => handleAddTransaction()}
          balanceOrActions={
            <button type="button" onClick={handleAddTransaction}>
              <PlusCircleIcon className="h-5 w-5 text-green-400" />
            </button>
          }
        />
      )
    }
    return renderTransaction(editingTransaction, TRANSACTION_NEW_INDEX)
  }

  const renderSummary = () => (
    <div className="text-right">
      <div> {transactions.length} transactions</div>
      <div>
        Sum: <MoneyAmount amountInCents={transactionSum} />
      </div>
    </div>
  )

  const renderReconcileSummary = () => (
    <div className="flex flex-row-reverse">
      <div className="text-right bg-blue-100 p-2 rounded-md">
        <h2 className="text-lg text-center">Reconciliation</h2>
        <div>
          Starting Balance:{' '}
          <MoneyAmount
            className="w-16 inline-block"
            amountInCents={reconcileState.initial}
          />
        </div>
        <div>
          Ending Balance:{' '}
          <MoneyAmount
            className="w-16 inline-block"
            amountInCents={reconciliationActualBalance({
              state: reconcileState,
              transactions,
            })}
            color
          />
        </div>
        <div>
          Expected Balance:{' '}
          <MoneyAmount
            className="w-16 inline-block"
            amountInCents={reconcileState.expected}
            color
          />
        </div>
        <div>
          Difference:{' '}
          <MoneyAmount
            className="w-16 inline-block"
            amountInCents={reconciliationDifference({
              state: reconcileState,
              transactions,
            })}
            color
          />
        </div>
        <div>
          <button
            type="button"
            className="button button-primary"
            onClick={handleConfirmReconciliation}
            disabled={
              !isReconciliationReady({
                state: reconcileState,
                transactions,
              }) || reconcileMutation.isPending
            }
          >
            Confirm
          </button>
          <button
            type="button"
            className="button button-danger"
            onClick={() =>
              setReconcileState({ ...reconcileState, active: false })
            }
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  )

  useEffect(() => {
    if (transactions && !isLoading) {
      if (focusId) {
        const index = transactions.findIndex((t) => t.id === focusId)
        if (index >= 0) {
          setSelectedIndex(index)
          // Do not clear this if it's not found because the loading triggers the useEffect twice
          setSearchParams({ focusId: '' })
        }
      } else if (selectedIndex === null && transactions.length > 0) {
        setSelectedIndex(transactions.length - 1)
      }
      setEditingTransaction(null)
    }
  }, [accountId, isLoading, transactions])

  useEffect(() => {
    setReconcileState({ ...reconcileState, active: false })
  }, [accountId])

  if (isError) {
    return <div>Error</div>
  }

  return (
    <GlobalHotKeys keyMap={keyMap} handlers={keymapHandlers} allowChanges>
      <div className="sticky bg-blue-100 p-2 flex justify-between top-0">
        <div className="flex flex-grow">
          <div>
            <PeriodSelect onSelect={handlePeriodSelect} />
          </div>
          <div className="px-2">
            <label htmlFor="transaction-search">
              Search:
              <input
                type="search"
                id="transaction-search"
                className="mx-2"
                onChange={handleSearchChange}
              />
            </label>
          </div>
        </div>
        <div>
          {!reconcileState.active && (
            <button
              type="button"
              onClick={handleStartReconcileMode}
              className="button button-default"
            >
              Reconcile
            </button>
          )}
          <button
            type="button"
            onClick={handleEditAccount}
            className="button button-default"
          >
            Edit Account
          </button>
        </div>
      </div>
      <div className="flex-grow">
        <div className="transaction-list p-3 w-full mb-28">
          {renderHeaders()}
          {isLoading ? (
            'Loading...'
          ) : (
            <>
              {transactions.map(renderTransaction)}
              {renderNewTransaction()}
              {renderSummary()}
              {reconcileState.active && renderReconcileSummary()}
            </>
          )}
        </div>
      </div>
    </GlobalHotKeys>
  )
}
